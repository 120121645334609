import { Avatar, Image } from 'antd'
import { IMember, memberStore } from 'Models/MemberModel'

import { DeleteConfirmButton } from 'Components'
import { IListItem } from 'Models/ListModel/ListItem'
import React from 'react'
import { Table } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

interface Props {
  listItems: IListItem[]
  onMemberClick: (member: IMember) => void
}

const Pointer = styled.div`
  cursor: pointer;
`

export const ListItemTable = observer((props: Props) => {
  const { listItems, onMemberClick } = props
  const items = listItems && listItems.slice()
  console.log('members:', memberStore.members.length) // work around to make this list rerender after members are loaded

  const columns = [
    {
      title: 'រូបថត',
      dataIndex: 'member.phoneNo',
      key: 'member.phoneNo',
      render: (text, record) => {
        if (record.member && record.member.photo) {
          return <Avatar size={70} src={<Image src={record.member.photo} />} />
        } else {
          return <Avatar size={70} icon={<UserOutlined />} />
        }
      },
    },
    {
      title: 'ងារ',
      dataIndex: 'member.prefix',
      key: 'name.prefix',
      render: (text, record) => {
        return (
          <Pointer onClick={() => onMemberClick(record.member)}>
            {record.member && record.member.prefix}
          </Pointer>
        )
      },
    },
    {
      title: 'ឈ្មោះ',
      dataIndex: 'member.name',
      key: 'name',
      render: (text, record) => {
        return (
          <Pointer onClick={() => onMemberClick(record.member)}>
            {record.member && record.member.name}
          </Pointer>
        )
      },
    },
    {
      title: 'តួនាទី',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'លេខទូរស័ព្ទ',
      dataIndex: 'phoneNo',
      key: 'phoneNo',
      render: (text, record) => {
        return (
          <Pointer onClick={() => onMemberClick(record.member)}>
            {record.member && record.member.phoneNo}
          </Pointer>
        )
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => {
        return (
          <DeleteConfirmButton
            deleting={record.isDeleting}
            onConfirmClick={() => record.delete()}
          />
        )
      },
    },
  ]

  return <Table columns={columns} dataSource={items} />
})
