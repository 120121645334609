import * as api from './api'

import React, { useEffect, useState } from 'react'

import { Select } from 'antd'
import { SelectValue } from 'antd/lib/select'

const { Option } = Select

interface Props {
  communeId: string | undefined
  onSelected?: (province: api.RegionEntity) => void
  onChange?: (value: SelectValue) => void
}

export const VillageSelector = (props: Props) => {
  const { communeId } = props
  const [entities, setEntities] = useState<api.RegionEntity[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (communeId) {
      getEntities()
    }
  }, [communeId])

  async function getEntities() {
    setLoading(true)
    const entities = await api.getVillagesByCommuneId(communeId)
    const sorted = entities.sort((a, b) => {
      return a.name.km.localeCompare(b.name.km)
    })
    setEntities(sorted)
    setLoading(false)
  }

  function onChange(value: SelectValue) {
    const village = entities.find((p) => p.id === value)
    props.onSelected && props.onSelected(village)
    props.onChange && props.onChange(village.name.km)
  }

  return (
    <Select
      showSearch
      optionFilterProp="children"
      {...props}
      loading={loading}
      onChange={onChange}
    >
      {entities.map((entity) => {
        return (
          <Option key={entity.id} value={entity.id}>
            {`${entity.name.km}`}
          </Option>
        )
      })}
    </Select>
  )
}
