import * as Colors from 'Constants/Colors'

import { IList, listStore } from 'Models/ListModel'
import React, { useState } from 'react'

import { Form } from 'antd'
import { ListForm } from '../Form/ListForm'
import { message } from 'antd'
import styled from 'styled-components'

const Container = styled.section`
  height: 100%;
`

const Title = styled.h4`
  background: ${Colors.SecondaryColor};
  padding: 10px;
  margin-bottom: 30px;
`

interface Props {
  onSaveFinish: () => void
  parentList: IList | undefined
}

export const ListAddContainer = (props: Props) => {
  const { onSaveFinish, parentList } = props
  console.log('parentList: ', parentList)
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()

  async function onSubmit(values) {
    console.log('onSubmit: ', values)
    setSaving(true)
    const hideMessage = message.loading('កំពុងរក្សាទុក', 0)
    const parentId = parentList && parentList.id
    try {
      const dataToSave = {
        ...values,
        parentId,
      }
      console.log('dataToSave: ', dataToSave)
      if (parentList) {
        await parentList.add(dataToSave)
      } else {
        await listStore.add(dataToSave)
      }

      hideMessage()
      form.resetFields()
      setSaving(false)
      onSaveFinish && onSaveFinish()
    } catch (e) {
      console.log(e)
      hideMessage()
      message.error('Error ' + e.message)
      setSaving(false)
    }
  }

  return (
    <Container>
      <Title>បន្ថែម{parentList && 'ទៅក្នុងបញ្ជី ' + parentList.name}</Title>

      <ListForm onSubmit={onSubmit} saving={saving} form={form}></ListForm>
    </Container>
  )
}
