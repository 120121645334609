import * as Colors from 'Constants/Colors'

import { Form, message } from 'antd'
import { IMember, memberStore } from 'Models/MemberModel'
import React, { useEffect, useState } from 'react'

import { MemberForm } from '../MemberAdd/MemberForm'
import moment from 'moment'
import styled from 'styled-components'

const Container = styled.section`
  margin-left: 30px;
  height: 100%;
  width: 100%;
`

const Title = styled.h4`
  background: ${Colors.SecondaryColor};
  padding: 10px;
  margin-bottom: 30px;
`

interface Props {
  member: IMember
  onSaveFinish: () => void
}

export const MemberEditContainer = (props: Props) => {
  const { onSaveFinish, member } = props
  const values = member
  const [form] = Form.useForm()
  const [saving, setSaving] = useState(false)
  const [uploadedPhotoUrl, setUploadedPhotoUrl] = useState<string>()

  const dateOfBirth = values.dateOfBirth
    ? moment(values.dateOfBirth).format('DD/MM/YYYY')
    : undefined

  const initialValues: any = {
    ...values,
    dateOfBirth,
  }

  useEffect(() => {
    setUploadedPhotoUrl(initialValues.photo)
  }, [])

  function onPhotoUploaded(url) {
    setUploadedPhotoUrl(url)
  }

  async function onSubmit(values) {
    console.log('onSubmit: ', values)
    setSaving(true)
    const hideMessage = message.loading('កំពុងរក្សាទុក', 0)
    let { dateOfBirth } = values
    if (dateOfBirth && dateOfBirth.length > 4) {
      dateOfBirth = moment(dateOfBirth, 'DD/MM/YYYY').valueOf()
    } else {
      dateOfBirth = undefined
    }

    const dataToSave = {
      ...values,
      dateOfBirth,
      photo: uploadedPhotoUrl,
    }
    try {
      await member.edit(dataToSave)
      form.resetFields()
      setUploadedPhotoUrl(undefined)
      setSaving(false)
      hideMessage()
      onSaveFinish && onSaveFinish()
    } catch (e) {
      setSaving(false)
      hideMessage()
      message.error(e.message)
    }
  }

  return (
    <Container>
      <Title>កែប្រែពត៌មានសមាជិក</Title>
      <MemberForm
        onSubmit={onSubmit}
        saving={saving}
        form={form}
        initialValues={initialValues}
        onFileUploaded={onPhotoUploaded}
        uploadedPhotoUrl={uploadedPhotoUrl}
      ></MemberForm>
    </Container>
  )
}
