import * as Colors from 'Constants/Colors'

import { IMember, memberStore } from 'Models/MemberModel'
import React, { useState } from 'react'

import { Form } from 'antd'
import { MemberForm } from './MemberForm'
import { message } from 'antd'
import moment from 'moment'
import styled from 'styled-components'

const Container = styled.section`
  height: 100%;
  width: 100%;
`

const Title = styled.h4`
  background: ${Colors.SecondaryColor};
  padding: 10px;
  margin-bottom: 30px;
`

interface Props {
  onSaveFinish: (member: IMember) => void
}

export const MemberAddContainer = (props) => {
  const { onSaveFinish } = props
  const [saving, setSaving] = useState(false)
  const [uploadedPhotoUrl, setUploadedPhotoUrl] = useState()
  const [form] = Form.useForm()

  const initialValues = {
    DateOfMember: moment().format('DD/MM/YYYY'),
  }

  function onPhotoUploaded(url) {
    setUploadedPhotoUrl(url)
  }

  async function onSubmit(values) {
    console.log('onSubmit: ', values)
    setSaving(true)
    const hideMessage = message.loading('កំពុងរក្សាទុក', 0)
    try {
      let { dateOfBirth } = values
      if (dateOfBirth && dateOfBirth.length > 4) {
        dateOfBirth = moment(dateOfBirth, 'DD/MM/YYYY').valueOf()
      } else {
        dateOfBirth = undefined
      }

      const dataToSave = {
        ...values,
        dateOfBirth,
        photo: uploadedPhotoUrl,
      }
      console.log('dataToSave: ', dataToSave)
      const addedMember = await memberStore.addMember(dataToSave)
      hideMessage()
      form.resetFields()
      setUploadedPhotoUrl(undefined)
      setSaving(false)
      onSaveFinish && onSaveFinish(addedMember)
    } catch (e) {
      console.log(e)
      hideMessage()
      message.error('Error ' + e.message)
      setSaving(false)
    }
  }

  return (
    <Container>
      <Title>បន្ថែមទិន្ន័យសមាជិក</Title>

      <MemberForm
        onSubmit={onSubmit}
        saving={saving}
        form={form}
        initialValues={initialValues}
        onFileUploaded={onPhotoUploaded}
        uploadedPhotoUrl={uploadedPhotoUrl}
      ></MemberForm>
    </Container>
  )
}
