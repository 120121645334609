import * as api from './api'

import {
  Instance,
  applySnapshot,
  flow,
  getParent,
  types,
} from 'mobx-state-tree'
import { List, listStore } from './ListModel'
import { Member, memberStore } from '../MemberModel'

const MemberReference = types.maybe(
  types.reference(
    types.late(() => Member),
    {
      get(identifier: string, parent): any {
        const member = memberStore.getOrLoadMember(identifier)
        return member
      },
      set(member) {
        return member.id
      },
    }
  )
)

const ListReference = types.maybe(
  types.reference(
    types.late(() => List),
    {
      get(identifier: string, parent): any {
        const list = listStore.getOrLoadList(identifier)
        return list
      },
      set(list) {
        return list.id
      },
    }
  )
)

export const ListItem = types
  .model('ListItem', {
    id: types.identifier,
    role: types.maybeNull(types.string),
    member: MemberReference,
    list: ListReference,
    isDeleting: false,
  })
  .actions((self) => ({
    delete: flow(function* () {
      self.isDeleting = true
      yield api.deleteItem(self.id)
      const store = getParent(self, 2) as Instance<typeof List>
      store.removeItem(self)
    }),
  }))

export interface IListItem extends Instance<typeof ListItem> {}
