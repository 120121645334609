import * as api from './api'

import { Modal, Select, message } from 'antd'
import React, { useEffect, useState } from 'react'

import { IMember } from 'Models/MemberModel'
import { MemberAddContainer } from 'Pages/MemberPage/MemberAdd/MemberAddContainer'
import styled from 'styled-components'

const { Option } = Select

const StyledSelect = styled(Select)`
  width: 250px;
`

const NewLabel = styled.div`
  background: #6dac9d;
  padding: 4px;
  color: white;
`

const Container = styled.div``

interface Props {
  onMemberSelected: (memberId: string) => void
  onChange?: (value: string) => void
}

export const MemberSelector = (props: Props) => {
  const { onMemberSelected } = props
  const [members, setMembers] = useState<IMember[]>([])
  const [loading, setLoading] = useState(false)
  const [shouldShowAddMemberModal, setShouldShowAddMemberModal] = useState(
    false
  )
  const [value, setValue] = useState<string>()

  useEffect(() => {
    searchMember('')
  }, [])

  async function searchMember(query: string) {
    setLoading(true)
    try {
      const filter = {
        where: {
          name: {
            like: `${query}`,
            options: 'i',
          },
        },
      }

      const members = await api.getMembersByFilter(JSON.stringify(filter))
      setMembers(members)
    } catch (e) {
      console.log('error: ', e)
      if (e.message) {
        message.error(e.message)
      }

      setMembers([])
    }
    setLoading(false)
  }

  function onChange(id: string) {
    if (id === '[ADD-NEW]') {
      setShouldShowAddMemberModal(true)
    } else {
      const member = members.find((m) => m.id === id)
      setValue(member.name)
      props.onChange && props.onChange(id)
      onMemberSelected && onMemberSelected(id)
    }
  }

  function onSearch(val: string) {
    searchMember(val)
  }

  function onNewMemberAdded(member: IMember) {
    setShouldShowAddMemberModal(false)
    props.onChange && props.onChange(member.id)
    setValue(member.name)
  }

  return (
    <Container>
      <StyledSelect
        showSearch
        loading={loading}
        placeholder="ជ្រើសរើសសមាជិក"
        optionFilterProp="children"
        onSearch={onSearch}
        filterOption={(input, option) => {
          if (option.value === '[ADD-NEW]') {
            return true
          }
          if (option && option.children) {
            const match =
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            return match
          } else {
            return false
          }
        }}
        {...props}
        onChange={onChange}
        value={value}
      >
        {members.map((member) => {
          return (
            <Option key={member.id} value={member.id}>
              {`${member.name}`}
            </Option>
          )
        })}
        <Option key={'NONE'} value={'[ADD-NEW]'}>
          <NewLabel>ADD NEW</NewLabel>
        </Option>
      </StyledSelect>
      <Modal
        visible={shouldShowAddMemberModal}
        onCancel={() => setShouldShowAddMemberModal(false)}
        footer={[]}
        width={1000}
      >
        <MemberAddContainer
          onSaveFinish={onNewMemberAdded}
        ></MemberAddContainer>
      </Modal>
    </Container>
  )
}
