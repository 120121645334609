import { Button, Checkbox, Form, Input } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { useHistory, useLocation } from 'react-router-dom'

import React from 'react'
import { login } from './api'
import styled from 'styled-components'
import { userStore } from 'Models/UserModel'

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const LoginPage = () => {
  let history = useHistory()
  let location = useLocation()

  async function onFinish(values) {
    try {
      const result = await login({ ...values, ttl: 4838400 })

      const user = {
        ...result.data.user,
        isLoggedIn: true,
        token: {
          ...result.data,
          user: undefined,
        },
      }

      userStore.setUser(user)
      const { rememberMe } = values
      if (rememberMe) {
        userStore.saveToStorage(user)
      } else {
        userStore.removeFromStorage()
      }

      let { from } = location.state || { from: { pathname: '/' } }

      history.replace(from)
    } catch (error) {
      alert('error ' + error.message)
    }
  }

  return (
    <Container>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          username: '',
          password: '',
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input your Username!',
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your Password!',
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item name="rememberMe" valuePropName="checked">
          <Checkbox>ចងចាំ</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            block
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </Container>
  )
}
