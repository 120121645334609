import * as Colors from 'Constants/Colors'

import { IList, listStore } from 'Models/ListModel'
import { Input, Modal } from 'antd'
import React, { useEffect, useState } from 'react'

import { Button } from 'antd'
import { IMember } from 'Models/MemberModel'
import { List } from './List'
import { ListAddContainer } from './ListAdd/ListAddContainer'
import { ListEditContainer } from './ListEdit/ListEditContainer'
import { ListItemAddContainer } from './ListItemAdd'
import { ListItemTable } from './ListItemTable'
import { MemberDetail } from 'Pages/MemberPage/MemberDetail'
import { ResidentListContainer } from './Resident'
import { SearchOutlined } from '@ant-design/icons'
import { Spinner } from 'Components'
import { Tabs } from 'antd'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

const { TabPane } = Tabs

const Container = styled.section`
  display: flex;
  flex-direction: row;
`

const Left = styled.div`
  flex: 0.4;
  min-width: 340px;
`

const Right = styled.div`
  margin-left: 20px;
  flex: 1;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  background: ${Colors.SecondaryColor};
  padding-left: 20px;
  height: 53px;
`

const MemberListHeader = styled(Row)`
  background: ${Colors.PrimaryColor};
`

interface Props {}

export const ListPage = observer((props: Props) => {
  const { lists } = listStore

  const [shouldShowAddListModal, setShouldShowAddListModal] = useState(false)
  const [shouldShowEditListModal, setShouldShowEditListModal] = useState(false)
  const [shouldShowAddListItemModal, setShouldShowAddListItemModal] = useState(
    false
  )
  const [parentList, setParentList] = useState<IList>()
  const [selectedList, setSelectedList] = useState<IList>()
  const [loadingList, setLoadingList] = useState(false)
  const [selectedMember, setSelectedMember] = useState<IMember>()
  const [shouldShowMemberDetail, setShouldShowMemberDetail] = useState(false)
  const [activeTabKey, setActiveTabKey] = useState('Members')

  useEffect(() => {
    loadList('')
  }, [])

  async function loadList(nameFilter: string) {
    setLoadingList(true)
    let filter: any = { where: { parentId: { eq: undefined } } }
    if (nameFilter.length > 0) {
      filter = {
        where: {
          name: {
            like: `${nameFilter}`,
            options: 'i',
          },
        },
      }
    }

    await listStore.load(filter)
    setLoadingList(false)
  }

  function onAddButtonClick() {
    setParentList(undefined)
    setShouldShowAddListModal(true)
  }

  function onListAddClick(parentList: IList) {
    setParentList(parentList)
    setShouldShowAddListModal(true)
  }

  function onListEditClick(list: IList) {
    setSelectedList(list)
    setShouldShowEditListModal(true)
  }

  async function onListClick(list: IList) {
    setSelectedList(list)
    await list.loadItems()
  }

  function onAddMemberButtonClick() {
    setShouldShowAddListItemModal(true)
  }

  async function onListFilterChange(e) {
    const text = e.target.value
    loadList(text)
  }

  function onMemberClick(member: IMember) {
    setSelectedMember(member)
    setShouldShowMemberDetail(true)
  }

  function onTabKeyChange(key: string) {
    setActiveTabKey(key)
  }

  return (
    <Container>
      <Left>
        <Row>
          <h4>បញ្ជី</h4>
          <Button onClick={onAddButtonClick} type="primary">
            បន្ថែម
          </Button>
        </Row>
        <Input
          size="large"
          placeholder="Filter"
          prefix={<SearchOutlined />}
          onChange={onListFilterChange}
        />

        {loadingList && <Spinner></Spinner>}

        {lists.map((list: IList) => {
          return (
            <List
              selectedId={selectedList && selectedList.id}
              list={list}
              key={list.id}
              onAddClick={onListAddClick}
              onEditClick={onListEditClick}
              onListClick={onListClick}
            ></List>
          )
        })}
      </Left>
      <Right>
        <Tabs defaultActiveKey="Members" onChange={onTabKeyChange}>
          <TabPane tab="សមាជិកចុះជួយ" key="Members">
            {activeTabKey === 'Members' && (
              <>
                <MemberListHeader>
                  <h4>បញ្ជី</h4>
                  {selectedList && (
                    <Button onClick={onAddMemberButtonClick} type="primary">
                      បន្ថែម
                    </Button>
                  )}
                </MemberListHeader>
                <ListItemTable
                  listItems={selectedList && selectedList.items}
                  onMemberClick={onMemberClick}
                ></ListItemTable>
              </>
            )}
          </TabPane>
          <TabPane tab="សមាជិកគណបក្សប្រជាជន" key="Residents">
            {activeTabKey === 'Residents' && (
              <ResidentListContainer
                list={selectedList}
              ></ResidentListContainer>
            )}
          </TabPane>
        </Tabs>
      </Right>

      {shouldShowAddListModal && (
        <Modal
          visible={shouldShowAddListModal}
          onCancel={() => setShouldShowAddListModal(false)}
          footer={[]}
          width={500}
        >
          <ListAddContainer
            onSaveFinish={() => setShouldShowAddListModal(false)}
            parentList={parentList}
          ></ListAddContainer>
        </Modal>
      )}

      {shouldShowEditListModal && (
        <Modal
          visible={shouldShowEditListModal}
          onCancel={() => setShouldShowEditListModal(false)}
          footer={[]}
          width={500}
        >
          <ListEditContainer
            onSaveFinish={() => setShouldShowEditListModal(false)}
            list={selectedList}
          ></ListEditContainer>
        </Modal>
      )}

      {shouldShowAddListItemModal && (
        <Modal
          visible={shouldShowAddListItemModal}
          onCancel={() => setShouldShowAddListItemModal(false)}
          footer={[]}
          width={1000}
        >
          <ListItemAddContainer
            onSaveFinish={() => setShouldShowAddListItemModal(false)}
            list={selectedList}
          ></ListItemAddContainer>
        </Modal>
      )}

      {shouldShowMemberDetail && (
        <Modal
          visible={shouldShowMemberDetail}
          onCancel={() => setShouldShowMemberDetail(false)}
          footer={[]}
          width={1000}
        >
          <MemberDetail member={selectedMember}></MemberDetail>
        </Modal>
      )}
    </Container>
  )
})
