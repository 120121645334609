import React, { useState } from 'react'

import { DeleteConfirmButton } from 'Components'
import { IMember } from 'Models/MemberModel'
import { message } from 'antd'
import { observer } from 'mobx-react-lite'
import placeHolderImage from './profile.png'
import styled from 'styled-components'

interface ContainerProps {
  selected: boolean
}

const Container = styled.div<ContainerProps>`
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
  background: ${(props) => (props.selected ? '#00cec9' : 'white')};

  &:hover {
    background: #00cec9;
  }
`

const Image = styled.img`
  width: 50px;
  height: 50px;
`

const Info = styled.div`
  margin-right: 10px;
  margin-left: 10px;
`

const Gender = styled.div``

const DeleteButton = styled(DeleteConfirmButton)`
  margin-left: auto;
  visibility: hidden;
  ${Container}:hover & {
    visibility: visible;
  }
`

interface Props {
  item: IMember
  index: number
  onMemberItemClick: (member: IMember) => void
  selected: boolean
  children?: any
}

export const MemberListItem = observer((props: Props) => {
  const { item, index, onMemberItemClick, selected } = props
  const imageUrl = item.photo ? item.photo + '?dim=100' : placeHolderImage

  const [deleting, setDeleting] = useState(false)

  async function onDeleteConfirmClick() {
    setDeleting(true)
    try {
      await item.delete()
      setDeleting(false)
    } catch (e) {
      message.error(e.message)
      setDeleting(false)
    }
  }

  return (
    <Container onClick={() => onMemberItemClick(item)} selected={selected}>
      <Image src={imageUrl}></Image>
      <Info>
        {index}. {item.name}
      </Info>
      <Gender>({item.gender})</Gender>
      <DeleteButton
        deleting={deleting}
        onConfirmClick={onDeleteConfirmClick}
      ></DeleteButton>
    </Container>
  )
})
