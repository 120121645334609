import { Redirect, Route } from 'react-router-dom'

import React from 'react'
import { observer } from 'mobx-react-lite'
import { userStore } from 'Models/UserModel'

export const PrivateRoute = observer(({ children, ...rest }) => {
  console.log(
    'private route, will not rerender if comment out this line' + userStore.name
  )

  return (
    <Route
      {...rest}
      render={({ location }) =>
        userStore.token.isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          ></Redirect>
        )
      }
    ></Route>
  )
})
