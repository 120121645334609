import * as Colors from 'Constants/Colors'

import React, { useState } from 'react'

import { Button } from 'antd'
import { IMember } from 'Models/MemberModel'
import { MemberEditContainer } from '../MemberEdit'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

const Container = styled.section`
  margin-left: 30px;
  padding: 20px;
  padding-top: 0px;
  height: 100%;
  width: 100%;
  overflow: scroll;
`

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  background: ${Colors.SecondaryColor};
  padding-left: 20px;
  height: 53px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid lightgray;
  padding: 15px;
`

const Label = styled.div`
  width: 170px;
  color: gray;
`

const Value = styled.div``

const AssignedList = styled.div`
  margin-top: 30px;
`

const ListItem = styled.div`
  margin: 10px;
`

const ATTRIBUTES = [
  { key: 'name', label: 'ឈ្មោះ', type: 'string' },
  { key: 'nameLatin', label: 'ឈ្មោះឡាតាំង', type: 'string' },
  { key: 'gender', label: 'ភេទ', type: 'string' },
  { key: 'dateOfBirth', label: 'ថ្ងៃខែឆ្នាំកំណើត', type: 'date' },
  { key: 'placeOfBirth', label: 'ទីកន្លែងកំណើត', type: 'string' },
  { key: 'idNo', label: 'លេខអត្តសញ្ញាណប័ណ្ឌ', type: 'string' },
  { key: 'job', label: 'តួនាទី/មុខរបរ', type: 'string' },
  { key: 'educationLevel', label: 'កម្រិតវប្បធម៌', type: 'string' },
  { key: 'phoneNo', label: 'លេខទូរស័ព្ទ', type: 'string' },
  { key: 'email', label: 'អ៊ីមែល', type: 'string' },
  { key: 'facebookName', label: 'ឈ្មោះក្នុងហ្វេសប៊ុក', type: 'string' },
]

interface Props {
  member: IMember | undefined
}

export const MemberDetail = observer((props: Props) => {
  const { member } = props
  const [isEdit, setIsEdit] = useState(false)

  function intToString(int) {
    if (int == 1) {
      return 'មាន'
    }
    return 'គ្មាន'
  }

  function onEditButtonClick() {
    setIsEdit(true)
  }

  function onSaveFinish() {
    setIsEdit(false)
  }

  if (isEdit) {
    return (
      <MemberEditContainer
        member={member}
        onSaveFinish={onSaveFinish}
      ></MemberEditContainer>
    )
  }

  const data = member || {}

  return (
    <Container>
      <TitleRow>
        <h4>ពត៌មានលម្អិត</h4>
        <Button onClick={onEditButtonClick} type="primary">
          កែប្រែ
        </Button>
      </TitleRow>

      {ATTRIBUTES.map((attribute) => {
        return (
          <Row key={attribute.key}>
            <Label>{attribute.label}:</Label>
            <Value>
              {attribute.type === 'date'
                ? data[attribute.key] &&
                  moment(data[attribute.key]).format('DD/MM/YYYY')
                : attribute.type === 'boolean'
                ? intToString(data[attribute.key])
                : data[attribute.key]}
            </Value>
          </Row>
        )
      })}

      <AssignedList>
        <div>ការតែងតាំង</div>
        {member &&
          member.listItems.map((listItem, index) => {
            return (
              <ListItem key={listItem.id}>
                <span>{index + 1}. </span>
                <span>{listItem.role} of </span>
                <span>{listItem.list && listItem.list.name}</span>
              </ListItem>
            )
          })}
      </AssignedList>
    </Container>
  )
})
