import { RESIDENT_SERVER_URL } from 'Constants/Server'
import axios from 'axios'

let _Token = undefined

export async function getToken(): Promise<string> {
  if (_Token) {
    return _Token
  }
  const url = `${RESIDENT_SERVER_URL}/api/clients/login`
  const data = {
    username: 'god',
    password: 'god',
  }
  const result = await axios.post(url, data)
  const token = result.data.id
  console.log('token: ', token)
  _Token = token
  return _Token
}

export const getHouseMembers = async (filter: any) => {
  const token = await getToken()
  const url = `${RESIDENT_SERVER_URL}/api/HouseMembers?filter=${JSON.stringify(
    filter
  )}`
  const config = {
    headers: {
      Authorization: token,
    },
  }
  const result = await axios.get(url, config)
  return result.data
}
