import axios from 'axios'
import { userStore } from 'Models/UserModel'

const setup = function () {
  axios.interceptors.response.use(undefined, (error) => {
    const { status } = error.response
    if (status === 401) {
      userStore.logout()
    }
  })
}

export default {
  setup,
}
