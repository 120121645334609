import {
  CommuneSelector,
  DistrictSelector,
  ProvinceSelector,
  VillageSelector,
} from 'Components'
import React, { useState } from 'react'

import { RegionEntity } from 'Components/GeoSelector/api'
import styled from 'styled-components'

const StyledProvinceSelector = styled(ProvinceSelector)`
  width: 300px;
`

const StyledDistrictSelector = styled(DistrictSelector)`
  width: 300px;
`

const StyledCommuneSelector = styled(CommuneSelector)`
  width: 300px;
`

const StyledVillageSelector = styled(VillageSelector)`
  width: 300px;
`

export const GeoSelectorTest = (props) => {
  const [province, setProvince] = useState<RegionEntity>()
  const [district, setDistrict] = useState<RegionEntity>()
  const [commune, setCommune] = useState<RegionEntity>()

  function onProvinceSelected(province: RegionEntity) {
    setProvince(province)
  }

  function onDistrictSelected(district: RegionEntity) {
    setDistrict(district)
  }

  function onCommuneSelected(commune: RegionEntity) {
    setCommune(commune)
  }

  return (
    <section>
      <h3>Geo Selector Test</h3>
      <div>Province</div>
      <StyledProvinceSelector
        onSelected={onProvinceSelected}
      ></StyledProvinceSelector>

      <div>District</div>
      <StyledDistrictSelector
        provinceId={province && province.id}
        onSelected={onDistrictSelected}
      ></StyledDistrictSelector>

      <div>Commune</div>
      <StyledCommuneSelector
        onSelected={onCommuneSelected}
        districtId={district && district.id}
      ></StyledCommuneSelector>

      <div>Village</div>
      <StyledVillageSelector
        communeId={commune && commune.id}
      ></StyledVillageSelector>
    </section>
  )
}
