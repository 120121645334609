import axios from 'axios'

const SERVER_URL = 'https://khmer-geo.wastecambodia.com'

export type RegionEntity = {
  id: string
  name: {
    km: string
    latin: string
  }
}

export const getProvinces = async (): Promise<RegionEntity[]> => {
  const url = `${SERVER_URL}/provinces`
  const { data } = await axios.get(url)
  return data
}

export const getProvinceByName = async (
  name: string
): Promise<RegionEntity> => {
  const url = `${SERVER_URL}/province/nameKm/${name}`
  const { data } = await axios.get(url)
  return data
}

export const getDistrictsByProvinceId = async (
  provinceId: string
): Promise<RegionEntity[]> => {
  const url = `${SERVER_URL}/districts/${provinceId}`
  const { data } = await axios.get(url)
  return data
}

export const getDistrictByName = async (
  name: string
): Promise<RegionEntity> => {
  const url = `${SERVER_URL}/district/nameKm/${name}`
  const { data } = await axios.get(url)
  return data
}

export const getCommunesByDistrictId = async (
  districtId: string
): Promise<RegionEntity[]> => {
  const url = `${SERVER_URL}/communes/${districtId}`
  const { data } = await axios.get(url)
  return data
}

export const getCommuneByName = async (name: string): Promise<RegionEntity> => {
  const url = `${SERVER_URL}/commune/nameKm/${name}`
  const { data } = await axios.get(url)
  return data
}

export const getVillagesByCommuneId = async (
  communeId: string
): Promise<RegionEntity[]> => {
  const url = `${SERVER_URL}/villages/${communeId}`
  const { data } = await axios.get(url)
  return data
}

export const getVillageByName = async (name: string): Promise<RegionEntity> => {
  const url = `${SERVER_URL}/villages/${name}`
  const { data } = await axios.get(url)
  return data
}
