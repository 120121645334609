import axios, { CancelTokenSource } from 'axios'

import { SERVER_URL } from 'Constants/Server'

export const deleteById = async (id: string) => {
  const url = `${SERVER_URL}/api/Members/${id}`
  const response = await axios.delete(url)
  return response.data
}

export const getMembers = async () => {
  const url = `${SERVER_URL}/api/Members`
  const response = await axios.get(url)
  return response.data
}

let getMemberByFilterAjaxRequest: CancelTokenSource
export const getMemberByFilter = async (filter: any) => {
  filter.include = 'listItems'
  if (getMemberByFilterAjaxRequest) {
    getMemberByFilterAjaxRequest.cancel()
  }
  getMemberByFilterAjaxRequest = axios.CancelToken.source()
  const url = `${SERVER_URL}/api/Members?filter=${JSON.stringify(filter)}`
  const response = await axios.get(url)
  const members = response.data.map((member) => {
    return {
      ...member,
      listItems: member.listItems.map((item) => {
        return {
          ...item,
          list: item.listId,
        }
      }),
    }
  })
  return members
}

export const getMemberById = async (id: string) => {
  const filter = {
    include: 'listItems',
  }
  const url = `${SERVER_URL}/api/Members/${id}?filter=${JSON.stringify(filter)}`
  const response = await axios.get(url)
  const member = {
    ...response.data,
    listItems: response.data.listItems.map((item) => {
      return {
        ...item,
        list: item.listId,
      }
    }),
  }
  return member
}

export const searchMembers = async (whereQuery: string) => {
  const url = `${SERVER_URL}/api/Members?filter=${whereQuery}`
  const response = await axios.get(url)
  return response.data
}

export const addMember = async (member: any) => {
  const url = `${SERVER_URL}/api/Members`
  const response = await axios.post(url, member)
  return response.data
}

export const editMember = async (values: any) => {
  const url = `${SERVER_URL}/api/Members/${values.id}`
  const data = values
  const response = await axios.patch(url, data)
  return response.data
}
