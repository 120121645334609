import * as api from './api'

import React, { useEffect, useState } from 'react'
import { Table, message } from 'antd'

import { IList } from 'Models/ListModel'
import { Spinner } from 'Components'
import styled from 'styled-components'

const Container = styled.section``

interface Props {
  list: IList
}

export const ResidentListContainer = (props: Props) => {
  const { list } = props
  const [members, setMembers] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!list) {
      return
    }

    async function fetchMembers() {
      setLoading(true)
      const filter = {
        where: {
          province: list.province,
        },
        limit: 3000,
      }

      const properties = ['district', 'commune', 'village']
      properties.forEach((prop) => {
        if (list[prop]) {
          filter.where[prop] = list[prop]
        }
      })

      try {
        const members = await api.getHouseMembers(filter)
        setMembers(members)
      } catch (e) {
        message.error(e.message)
      }

      setLoading(false)
    }

    fetchMembers()
  }, [list])

  const columns = [
    {
      title: 'ឈ្មោះ',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'ភេទ',
      dataIndex: 'gender',
      key: 'gender',
    },
    {
      title: 'មុខរបរ',
      dataIndex: 'job',
      key: 'job',
    },
    {
      title: 'លេខទូរស័ព្ទ',
      dataIndex: 'phoneNo',
      key: 'phoneNo',
    },
  ]

  return (
    <Container>
      {loading ? (
        <Spinner></Spinner>
      ) : (
        <Table columns={columns} dataSource={members} />
      )}
    </Container>
  )
}
