import { Button, Spin } from 'antd'
import { DeleteOutlined, WarningFilled } from '@ant-design/icons'
import React, { useState } from 'react'

interface Props {
  deleting: boolean
  onConfirmClick: () => void
}

export const DeleteConfirmButton = (props: Props) => {
  const { deleting } = props
  const [showConfirmButton, setShowConfirmButton] = useState(false)

  function onClick(e) {
    e.stopPropagation()
    setShowConfirmButton(true)
  }

  function onConfirmClick(e) {
    e.stopPropagation()
    setShowConfirmButton(false)
    props.onConfirmClick && props.onConfirmClick()
  }

  if (deleting) {
    return (
      <Button shape="circle">
        <Spin></Spin>
      </Button>
    )
  }

  if (showConfirmButton) {
    return (
      <Button
        {...props}
        danger
        type="primary"
        onClick={onConfirmClick}
        shape="circle"
        icon={<WarningFilled />}
      ></Button>
    )
  }

  return (
    <Button
      {...props}
      onClick={onClick}
      shape="circle"
      icon={<DeleteOutlined />}
    ></Button>
  )
}
