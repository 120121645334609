import * as Colors from 'Constants/Colors'

import { IList, listStore } from 'Models/ListModel'
import React, { useState } from 'react'

import { AddForm } from './Form'
import { Form } from 'antd'
import { message } from 'antd'
import styled from 'styled-components'

const Container = styled.section`
  height: 100%;
`

const Title = styled.h4`
  background: ${Colors.SecondaryColor};
  padding: 10px;
  margin-bottom: 30px;
`

interface Props {
  onSaveFinish: () => void
  list: IList | undefined
}

export const ListItemAddContainer = (props: Props) => {
  const { onSaveFinish, list } = props
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()

  async function onSubmit(values) {
    console.log('onSubmit: ', values)
    setSaving(true)
    const hideMessage = message.loading('កំពុងរក្សាទុក', 0)
    const listId = list && list.id
    try {
      const dataToSave = {
        ...values,
        listId,
      }
      await list.addItem(dataToSave)

      hideMessage()
      form.resetFields()
      setSaving(false)
      onSaveFinish && onSaveFinish()
    } catch (e) {
      console.log(e)
      hideMessage()
      message.error('Error ' + e.message)
      setSaving(false)
    }
  }

  return (
    <Container>
      <Title>បន្ថែមទៅបញ្ជី {list.name}</Title>

      <AddForm onSubmit={onSubmit} saving={saving} form={form}></AddForm>
    </Container>
  )
}
