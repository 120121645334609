import 'antd/dist/antd.css'
import './App.css'

import * as Sentry from '@sentry/react'

import {
  HomePage,
  ListPage,
  LoginPage,
  MemberSearchPage,
  TestPage,
} from 'Pages'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { HeaderSection } from 'Header'
import { Layout } from 'antd'
import { PrivateRoute } from 'Auth'
import React from 'react'
import { observer } from 'mobx-react-lite'

const { Content } = Layout

const App = observer(() => {
  return (
    <Router>
      <Layout>
        <HeaderSection></HeaderSection>
        <Content
          style={{
            padding: '0 0',
            marginTop: 0,
            backgroundColor: 'white',
            position: 'relative',
          }}
        >
          <Switch>
            <Route exact path="/login" component={LoginPage} />

            <PrivateRoute exact path="/">
              <ListPage></ListPage>
            </PrivateRoute>

            <PrivateRoute exact path="/list">
              <ListPage></ListPage>
            </PrivateRoute>

            <PrivateRoute exact path="/member">
              <HomePage></HomePage>
            </PrivateRoute>

            <PrivateRoute exact path="/search">
              <MemberSearchPage></MemberSearchPage>
            </PrivateRoute>

            <Route exact path="/test" component={TestPage} />
          </Switch>
        </Content>
      </Layout>
    </Router>
  )
})

export default Sentry.withProfiler(App)
