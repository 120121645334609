import { Input, message } from 'antd'
import React, { useEffect, useState } from 'react'

import { MemberList } from './MemberList'
import { Spinner } from 'Components'
import { memberStore } from 'Models/MemberModel'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

const Container = styled.section`
  padding: 20px;
`

const SearchRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
`

const SearchItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  align-items: center;
`

const Label = styled.div`
  margin-right: 5px;
`

export const MemberSearchPage = observer((props) => {
  const { members = {} } = memberStore
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')

  useEffect(() => {
    async function loadMembers() {
      const filter = {
        where: {
          name: {
            like: `${name}`,
            options: 'i',
          },
        },
      }

      try {
        setLoading(true)
        await memberStore.searchMembers(JSON.stringify(filter))
        setLoading(false)
      } catch (e) {
        message.error('Cannot load members ' + e.message)
        setLoading(false)
      }
    }

    loadMembers()
  }, [name])

  async function onNameChange(event) {
    const value = event.target.value
    setName(value)
  }

  return (
    <Container>
      <SearchRow>
        <SearchItem>
          <Label>ឈ្មោះ</Label>
          <Input onChange={onNameChange}></Input>
        </SearchItem>
      </SearchRow>
      {loading && <Spinner></Spinner>}
      <MemberList members={members}></MemberList>
    </Container>
  )
})
