import * as api from './api'

import { OptionType, SelectValue } from 'antd/lib/select'
import React, { useEffect, useState } from 'react'

import { Select } from 'antd'

const { Option } = Select

interface Props {
  onSelected?: (province: api.RegionEntity) => void
  onChange?: (value: SelectValue) => void
}

export const ProvinceSelector = (props: Props) => {
  const [provinces, setProvinces] = useState<api.RegionEntity[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getProvinces()
  }, [])

  async function getProvinces() {
    setLoading(true)
    const provinces = await api.getProvinces()
    const sorted = provinces.sort((a, b) => {
      return a.name.km.localeCompare(b.name.km)
    })
    setProvinces(sorted)
    setLoading(false)
  }

  function onChange(value: SelectValue) {
    const province = provinces.find((p) => p.id === value)
    props.onSelected && props.onSelected(province)
    props.onChange && props.onChange(province.name.km)
  }

  return (
    <Select
      showSearch
      optionFilterProp="children"
      {...props}
      loading={loading}
      onChange={onChange}
    >
      {provinces.map((province) => {
        return (
          <Option key={province.id} value={province.id}>
            {`${province.name.km}`}
          </Option>
        )
      })}
    </Select>
  )
}
