import React, { useState } from 'react'

import { GeoSelectorTest } from 'Components/GeoSelector/Test'

export const TestPage = (props) => {
  return (
    <section>
      <h3>Test</h3>
      <GeoSelectorTest></GeoSelectorTest>
    </section>
  )
}
