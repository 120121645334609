import * as Colors from 'Constants/Colors'

import { Button as BaseButton, Spin } from 'antd'
import {
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  RightOutlined,
} from '@ant-design/icons'
import React, { useState } from 'react'

import { DeleteConfirmButton } from 'Components'
import { IList } from 'Models/ListModel'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Button = styled(BaseButton)`
  margin: 3px;
`

const DeleteButton = styled(DeleteConfirmButton)`
  margin: 3px;
`

interface RowProps {
  selected: boolean
}

const Row = styled.div<RowProps>`
  display: flex;
  position: relative;
  min-height: 60px;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 4px;
  border-radius: 4px;
  cursor: pointer;
  background: ${(props) => (props.selected ? '#00cec9' : 'white')};
  &:hover {
    background: #00cec9;
  }
`

const Subtitle = styled.div`
  color: ${Colors.SubtitleColor};
  font-size: smaller;
`

const Controls = styled.div`
  position: absolute;
  right: 4px;
  visibility: hidden;
  ${Row}:hover & {
    visibility: visible;
  }
`

const Children = styled.div`
  margin-left: 26px;
`

interface Props {
  list: IList
  onAddClick: (list: IList) => void
  onListClick: (list: IList) => void
  onEditClick: (list: IList) => void
  selectedId: string | null
}

export const List = observer((props: Props) => {
  const { list, onAddClick, onEditClick, selectedId } = props

  const [loading, setLoading] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const selected = list.id === selectedId

  async function toggleExpand() {
    if (expanded) {
      setExpanded(false)
    } else {
      expand()
    }
  }

  async function expand() {
    setLoading(true)
    setExpanded(true)
    await list.loadChildren()
    setLoading(false)
  }

  function onListClick(list: IList) {
    if (!expanded) {
      expand()
    }
    props.onListClick(list)
  }

  return (
    <Container>
      <Row onClick={() => onListClick(list)} selected={selected}>
        <div>
          <div>{list.name}</div>
          <Subtitle>
            {list.province} {list.district} {list.commune} {list.village}
          </Subtitle>
        </div>
        <Controls>
          <Button
            shape="circle"
            icon={expanded ? <DownOutlined /> : <RightOutlined />}
            onClick={toggleExpand}
          />
          <Button
            shape="circle"
            icon={<PlusOutlined />}
            onClick={() => onAddClick(list)}
          />
          <Button
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => onEditClick(list)}
          />
          <DeleteButton
            deleting={list.isDeleting}
            onConfirmClick={() => list.delete()}
          ></DeleteButton>
        </Controls>
      </Row>
      {expanded && list.children && (
        <Children>
          {loading && <Spin></Spin>}
          {list.children.map((child) => {
            return (
              <List
                key={child.id}
                list={child}
                onAddClick={onAddClick}
                onListClick={onListClick}
                onEditClick={onEditClick}
                selectedId={selectedId}
              ></List>
            )
          })}
        </Children>
      )}
    </Container>
  )
})
