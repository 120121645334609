import { Button, Form, Input, Select } from 'antd'

import { MemberSelector } from 'Components'
import React from 'react'

const { Option } = Select

export const AddForm = (props) => {
  const { onSubmit, saving, form, initialValues } = props

  function onMemberIdSelected(id) {
    console.log('onMemberIdSelected: ', id)
  }

  return (
    <Form
      onFinish={onSubmit}
      size="large"
      labelCol={{ span: 7 }}
      form={form}
      initialValues={initialValues}
    >
      <Form.Item
        label="សមាជិក"
        name="memberId"
        rules={[{ required: true, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <MemberSelector onMemberSelected={onMemberIdSelected}></MemberSelector>
      </Form.Item>
      <Form.Item
        label="តួនាទី"
        name="role"
        rules={[{ required: true, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <Select>
          <Option value="ប្រធាន">ប្រធាន</Option>
          <Option value="អនុប្រធាន">អនុប្រធាន</Option>
          <Option value="អនុប្រធានប្រចាំការ">អនុប្រធានប្រចាំការ</Option>
          <Option value="អនុប្រធានអចិន្ត្រៃយ៍">អនុប្រធានអចិន្ត្រៃយ៍</Option>
          <Option value="សមាជិក">សមាជិក</Option>
          <Option value="សមាជិកប្រចាំការ">សមាជិកប្រចាំការ</Option>
          <Option value="សមាជិកអចិន្ត្រៃយ៍">សមាជិកអចិន្ត្រៃយ៍</Option>
          <Option value="ផ្សេងៗ">ផ្សេងៗ</Option>
        </Select>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 5 }}>
        <Button type="primary" htmlType="submit" loading={saving}>
          រក្សារទុក
        </Button>
      </Form.Item>
    </Form>
  )
}
