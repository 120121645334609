import { Button, Divider, Form, Input, Select } from 'antd'

import { ImageUpload } from 'Components/ImageUpload'
import MaskedInput from 'antd-mask-input'
import React from 'react'
import moment from 'moment'
import styled from 'styled-components'

const StyledForm = styled(Form)``

const { Option } = Select

const validateBirthDate = (rule, value) => {
  if (!value) {
    return Promise.resolve()
  }
  if (value.indexOf('_') > -1) {
    return Promise.reject('មិនត្រូវមានសញ្ញា _')
  }

  const date = moment(value, 'DD/MM/YYYY')

  const earliestDate = moment('31/12/1900', 'DD/MM/YYYY')
  if (date.isBefore(earliestDate)) {
    return Promise.reject('មិនអាចមុនថ្ងៃ 31/12/1900 ទេ')
  }

  const now = moment()
  if (date.isAfter(now)) {
    return Promise.reject('មិនអាចក្រោយថ្ងៃនេះទេ')
  }

  return Promise.resolve()
}

export const MemberForm = (props) => {
  const {
    onSubmit,
    saving,
    form,
    onFileUploaded,
    uploadedPhotoUrl,
    initialValues,
  } = props

  return (
    <StyledForm
      onFinish={onSubmit}
      size="large"
      labelCol={{ span: 5 }}
      form={form}
      initialValues={initialValues}
    >
      <Form.Item label="រូបថត">
        <ImageUpload
          onFileUploaded={onFileUploaded}
          uploadedPhotoUrl={uploadedPhotoUrl}
        ></ImageUpload>
      </Form.Item>

      <Form.Item
        label="ឈ្មោះ"
        name="name"
        rules={[{ required: true, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="ឈ្មោះឡាតាំង"
        name="nameLatin"
        rules={[{ required: false, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="ងារ"
        name="prefix"
        rules={[{ required: false, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <Select>
          <Option value="ឯកឧត្ដម">ឯកឧត្ដម</Option>
          <Option value="ឧកញ៉ា">ឧកញ៉ា</Option>
          <Option value="លោកជំទាវ">លោកជំទាវ</Option>
          <Option value="លោក">លោក</Option>
          <Option value="លោកស្រី">លោកស្រី</Option>
          <Option value="កញ្ញា">កញ្ញា</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="ភេទ"
        name="gender"
        rules={[{ required: true, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <Select>
          <Option value="ប្រុស">ប្រុស</Option>
          <Option value="ស្រី">ស្រី</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="លេខទូរស័ព្ទ"
        name="phoneNo"
        rules={[
          { required: true, message: 'សូមបញ្ចូលតម្លៃ' },
          { max: 10, message: 'ត្រឹម១០ខ្ទង់ចុះ' },
          { min: 9, message: 'ចាប់ពី៩ខ្ទង់ឡើង' },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="ថ្ងៃខែឆ្នាំកំណើត (DD/MM/YYYY)"
        name="dateOfBirth"
        rules={[{ validator: validateBirthDate }]}
      >
        <MaskedInput mask="11/11/1111" size="20" />
      </Form.Item>

      <Form.Item
        label="ទីកន្លែងកំណើត (រាជធានី/ខេត្ត)"
        name="placeOfBirth"
        rules={[{ required: false, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="លេខអត្តសញ្ញាណប័ណ្ឌ"
        name="idNo"
        rules={[{ max: 9, message: 'ត្រឹម9ខ្ទង់ចុះ' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="តួនាទី/មុខរបរ"
        name="job"
        rules={[{ required: false, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="កម្រិតវប្បធម៌"
        name="educationLevel"
        rules={[{ required: false, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="អ៊ីមែល"
        name="email"
        rules={[{ required: false, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="ឈ្មោះក្នុងហ្វេសប៊ុក"
        name="facebookName"
        rules={[{ required: false, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 3 }}>
        <Button type="primary" htmlType="submit" loading={saving}>
          រក្សារទុក
        </Button>
      </Form.Item>
    </StyledForm>
  )
}
