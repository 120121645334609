import axios, { CancelTokenSource } from 'axios'

import { SERVER_URL } from 'Constants/Server'

export const add = async (values: any) => {
  const url = `${SERVER_URL}/api/Lists`
  const response = await axios.post(url, values)
  return response.data
}

export const editList = async (values: any) => {
  const url = `${SERVER_URL}/api/Lists/${values.id}`
  const data = values
  const response = await axios.patch(url, data)
  return response.data
}

export const get = async () => {
  const url = `${SERVER_URL}/api/Lists`
  const response = await axios.get(url)
  return response.data
}

export const getListById = async (id: string) => {
  const url = `${SERVER_URL}/api/Lists/${id}`
  const response = await axios.get(url)
  return response.data
}

export const getRootLists = async () => {
  const filter = { where: { parentId: { eq: undefined } } }
  const url = `${SERVER_URL}/api/Lists?filter=${JSON.stringify(filter)}`
  const response = await axios.get(url)
  return response.data
}

let getListAjaxRequest: CancelTokenSource
export const getListByFilter = async (filter: any) => {
  if (getListAjaxRequest) {
    getListAjaxRequest.cancel()
  }

  getListAjaxRequest = axios.CancelToken.source()
  const url = `${SERVER_URL}/api/Lists?filter=${JSON.stringify(filter)}`
  const response = await axios.get(url)
  return response.data
}

export const deleteById = async (id: string) => {
  const url = `${SERVER_URL}/api/Lists/${id}`
  const response = await axios.delete(url)
  return response.data
}

export const getChildren = async (parentId) => {
  const filter = { where: { parentId: { eq: parentId } } }
  const url = `${SERVER_URL}/api/Lists?filter=${JSON.stringify(filter)}`
  const response = await axios.get(url)
  return response.data
}

export const getItems = async (listId) => {
  const filter = { where: { listId: { eq: listId } } }
  const url = `${SERVER_URL}/api/ListItems?filter=${JSON.stringify(filter)}`
  const response = await axios.get(url)
  return response.data
}

export const addItem = async (values) => {
  const url = `${SERVER_URL}/api/ListItems`
  const response = await axios.post(url, values)
  return response.data
}

export const deleteItem = async (id: string) => {
  const url = `${SERVER_URL}/api/ListItems/${id}`
  const response = await axios.delete(url)
  return response.data
}
