import * as api from 'Components/GeoSelector/api'

import { Button, Divider, Form, Input, Select } from 'antd'
import {
  CommuneSelector,
  DistrictSelector,
  ProvinceSelector,
  VillageSelector,
} from 'Components'
import React, { useEffect, useState } from 'react'

import { IList } from 'Models/ListModel'
import { RegionEntity } from 'Components/GeoSelector/api'

interface Props {
  onSubmit: (values: any) => void
  saving?: boolean
  form?: any
  initialValues?: any
}

export const ListForm = (props) => {
  const { onSubmit, saving, form, initialValues = {} } = props
  const [province, setProvince] = useState<RegionEntity>()
  const [district, setDistrict] = useState<RegionEntity>()
  const [commune, setCommune] = useState<RegionEntity>()

  useEffect(() => {
    const {
      province: provinceName,
      district: districtName,
      commune: communeName,
    } = initialValues

    if (provinceName) {
      setProvinceByName(provinceName)
    }

    if (districtName) {
      setDistrictByName(districtName)
    }

    if (communeName) {
      setCommuneByName(communeName)
    }
  }, [])

  async function setProvinceByName(name: string) {
    const province = await api.getProvinceByName(name)
    setProvince(province)
  }

  async function setDistrictByName(name: string) {
    const district = await api.getDistrictByName(name)
    setDistrict(district)
  }

  async function setCommuneByName(name: string) {
    const commune = await api.getCommuneByName(name)
    console.log('commune: ', commune)
    setCommune(commune)
  }

  function onProvinceSelected(province: RegionEntity) {
    setProvince(province)
  }

  function onDistrictSelected(district: RegionEntity) {
    setDistrict(district)
  }

  function onCommuneSelected(commune: RegionEntity) {
    setCommune(commune)
  }

  return (
    <Form
      onFinish={onSubmit}
      size="large"
      labelCol={{ span: 4 }}
      form={form}
      initialValues={initialValues}
    >
      <Form.Item
        label="ឈ្មោះបញ្ជី"
        name="name"
        rules={[{ required: true, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="ខេត្ត"
        name="province"
        rules={[{ required: true, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <ProvinceSelector onSelected={onProvinceSelected} />
      </Form.Item>
      <Form.Item
        label="ស្រុក"
        name="district"
        rules={[{ required: false, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <DistrictSelector
          provinceId={province && province.id}
          onSelected={onDistrictSelected}
        />
      </Form.Item>
      <Form.Item
        label="ឃុំ"
        name="commune"
        rules={[{ required: false, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <CommuneSelector
          onSelected={onCommuneSelected}
          districtId={district && district.id}
        />
      </Form.Item>
      <Form.Item
        label="ភូមិ"
        name="village"
        rules={[{ required: false, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <VillageSelector communeId={commune && commune.id} />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 2 }}>
        <Button type="primary" htmlType="submit" loading={saving}>
          រក្សារទុក
        </Button>
      </Form.Item>
    </Form>
  )
}
