import React, { useState } from 'react'

import { MemberAddContainer } from './MemberAdd'
import { MemberDetail } from './MemberDetail'
import { MemberListContainer } from './MemberList'
import { Modal } from 'antd'
import styled from 'styled-components'

const Container = styled.section`
  height: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: row;
`

const Left = styled.section`
  height: 100%;
  flex: 0.2;
  min-width: 340px;
`

const Right = styled.section`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
`

export const HomePage = (props) => {
  const [shouldShowAddMemberModal, setShouldShowAddMemberModal] = useState(
    false
  )
  const [loadingMember, setLoadingMember] = useState(false)

  const [selectedMember, setSelectedMember] = useState()

  function onAddMemberButtonClick() {
    setShouldShowAddMemberModal(true)
  }

  function onMemberSaveFinish() {
    setShouldShowAddMemberModal(false)
  }

  function onMemberItemClick(member) {
    setSelectedMember(member)
  }

  return (
    <Container>
      <Left>
        <MemberListContainer
          onAddButtonClick={onAddMemberButtonClick}
          onMemberItemClick={onMemberItemClick}
        ></MemberListContainer>
      </Left>
      <Right>
        <MemberDetail member={selectedMember}></MemberDetail>
      </Right>

      <Modal
        visible={shouldShowAddMemberModal}
        onCancel={() => setShouldShowAddMemberModal(false)}
        footer={[]}
        width={1000}
      >
        <MemberAddContainer
          onSaveFinish={onMemberSaveFinish}
        ></MemberAddContainer>
      </Modal>
    </Container>
  )
}
