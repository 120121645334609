import * as api from './api'

import {
  Instance,
  applySnapshot,
  flow,
  getParent,
  types,
} from 'mobx-state-tree'

import { ListItem } from '../ListModel/ListItem'
import { userStore } from 'Models/UserModel'

export const Member = types
  .model('Member', {
    id: types.identifier,
    name: types.maybeNull(types.string),
    nameLatin: types.maybeNull(types.string),
    gender: types.maybeNull(types.string),
    job: types.maybeNull(types.string),
    phoneNo: types.maybeNull(types.string),
    dateOfBirth: types.union(
      types.maybeNull(types.number),
      types.maybeNull(types.string)
    ),
    placeOfBirth: types.maybeNull(types.string),
    idNo: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    facebookName: types.maybeNull(types.string),
    photo: types.maybeNull(types.string),
    educationLevel: types.maybeNull(types.string),
    workplace: types.maybeNull(types.string),
    prefix: types.maybeNull(types.string),
    listItems: types.array(types.late(() => ListItem)),
  })
  .actions((self) => ({
    delete: flow(function* () {
      yield api.deleteById(self.id)
      const store = getParent(self, 2) as Instance<typeof MemberStore>
      store.removeMember(self)
    }),
    edit: flow(function* (values) {
      const data = {
        id: self.id,
        ...values,
        editor: userStore.name,
      }
      yield api.editMember(data)
      const updatedData = {
        ...data,
        listItems: self.listItems,
      }
      applySnapshot(self, updatedData)
    }),
  }))

const MemberStore = types
  .model('MemberStore', {
    members: types.array(Member),
    selectedMember: types.maybe(types.safeReference(Member)),
  })
  .actions((self) => ({
    loadMember: flow(function* loadMember(id: string) {
      const memberData = yield api.getMemberById(id)
      self.members.push(memberData)
      const member = self.members.find((m) => m.id === id)
      return member
    }),
    loadMembers: flow(function* (filter: any) {
      const members = yield api.getMemberByFilter(filter)
      self.members = members
    }),
    searchMembers: flow(function* (whereQuery) {
      const members = yield api.searchMembers(whereQuery)
      self.members = members
    }),
    setSelectedMember(member: string | undefined) {
      // @ts-ignore:
      self.selectedMember = member
    },
    addMember: flow(function* (member) {
      const data = {
        ...member,
        editor: userStore.name,
      }
      const addedMember = yield api.addMember(data)
      self.members.push(addedMember)
      return addedMember
    }),
    removeMember(item: any) {
      self.members.splice(self.members.indexOf(item), 1)
    },
  }))
  .views((self) => ({
    // Important: a view so that the reference will automatically react to the reference being changed!
    getOrLoadMember(id: string) {
      const member = self.members.find((m) => m.id === id) || null
      if (!member) {
        setImmediate(() => self.loadMember(id))
      }
      return member
    },
  }))

export interface IMember extends Instance<typeof Member> {}
export const memberStore = MemberStore.create({})
