import * as api from './api'

import {
  Instance,
  applySnapshot,
  flow,
  getParent,
  types,
} from 'mobx-state-tree'

import { ListItem } from './ListItem'
import { userStore } from 'Models/UserModel'

export const List = types
  .model('List', {
    id: types.identifier,
    name: types.maybeNull(types.string),
    province: types.maybeNull(types.string),
    district: types.maybeNull(types.string),
    commune: types.maybeNull(types.string),
    village: types.maybeNull(types.string),
    children: types.optional(types.array(types.late(() => List)), []),
    items: types.optional(types.array(types.late(() => ListItem)), []),
    isDeleting: false,
  })
  .actions((self) => ({
    delete: flow(function* () {
      self.isDeleting = true
      yield api.deleteById(self.id)
      const store = getParent(self, 2) as Instance<typeof ListStore>
      store.removeMember(self)
    }),
    removeMember(item: any) {
      self.children.splice(self.children.indexOf(item), 1)
    },
    edit: flow(function* (values) {
      const data = {
        id: self.id,
        ...values,
        editor: userStore.name,
      }
      yield api.editList(data)
      const dataToApply = {
        ...self,
        ...data,
      }
      applySnapshot(self, dataToApply)
    }),
    add: flow(function* (values) {
      const data = {
        ...values,
        editor: userStore.name,
        parentId: self.id,
      }
      const result = yield api.add(data)
      self.children.push(result)
    }),
    loadChildren: flow(function* () {
      const lists = yield api.getChildren(self.id)
      self.children = lists
    }),
    loadItems: flow(function* () {
      const items = yield api.getItems(self.id)
      const mappedItems = items.map((item) => ({
        ...item,
        member: item.memberId,
      }))
      self.items = mappedItems
    }),
    addItem: flow(function* (values) {
      const item = yield api.addItem(values)
      const mappedItem = {
        ...item,
        member: item.memberId,
      }
      self.items.push(mappedItem)
    }),
    removeItem(item: any) {
      self.items.splice(self.items.indexOf(item), 1)
    },
  }))

const ListStore = types
  .model('ListStore', {
    lists: types.array(List),
    selectedList: types.maybe(types.safeReference(List)),
    hiddenLists: types.array(List),
  })
  .actions((self) => ({
    load: flow(function* (filter: any) {
      if (filter.length === 0) {
        const lists = yield api.getRootLists()
        self.lists = lists
      } else {
        const lists = yield api.getListByFilter(filter)
        self.lists = lists
      }
    }),
    loadList: flow(function* loadList(id: string) {
      const listData = yield api.getListById(id)
      self.hiddenLists.push(listData)
      const list = self.hiddenLists.find((m) => m.id === id)
      return list
    }),
    setSelectedList(listId: string | undefined) {
      self.selectedList = listId
    },
    add: flow(function* (values) {
      const data = {
        ...values,
        editor: userStore.name,
      }
      const result = yield api.add(data)
      self.lists.push(result)
    }),
    removeMember(item: any) {
      self.lists.splice(self.lists.indexOf(item), 1)
    },
  }))
  .views((self) => ({
    // Important: a view so that the reference will automatically react to the reference being changed!
    getOrLoadList(id: string) {
      const list = self.hiddenLists.find((m) => m.id === id) || null
      if (!list) {
        setImmediate(() => self.loadList(id))
      }
      return list
    },
  }))

export interface IList extends Instance<typeof List> {}
export const listStore = ListStore.create({})
