import axios, { CancelTokenSource } from 'axios'

import { SERVER_URL } from 'Constants/Server'

let ajaxRequest: CancelTokenSource
export const getMembersByFilter = async (filter: string) => {
  if (ajaxRequest) {
    ajaxRequest.cancel()
  }

  ajaxRequest = axios.CancelToken.source()
  const url = `${SERVER_URL}/api/Members?filter=${filter}`
  const response = await axios.get(url)
  return response.data
}
