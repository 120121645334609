import React from 'react'
import { Table } from 'antd'
import moment from 'moment'
import { observer } from 'mobx-react-lite'

export const MemberList = observer((props) => {
  const { members } = props

  const columns = [
    {
      title: 'ឈ្មោះ',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'ឈ្មោះអក្សរឡាតាំង',
      dataIndex: 'nameLatin',
      key: 'nameLatin',
    },
    {
      title: 'ភេទ',
      dataIndex: 'gender',
      key: 'gender',
    },
    {
      title: 'មុខរបរ',
      dataIndex: 'job',
      key: 'job',
    },
    {
      title: 'លេខទូរស័ព្ទ',
      dataIndex: 'phoneNo',
      key: 'phoneNo',
    },
    {
      title: 'ថ្ងៃខែឆ្នាំកំណើត',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
      render: (text, record) => {
        if (text) {
          return <span>{moment(text).format('YYYY-MM-DD')}</span>
        }
        return null
      },
    },
    {
      title: 'ទីកន្លែងកំណើត',
      dataIndex: 'placeOfBirth',
      key: 'placeOfBirth',
    },
    {
      title: 'លេខអត្តសញ្ញាណប័ណ្ណ',
      dataIndex: 'idNo',
      key: 'idNo',
    },
    {
      title: 'អ៊ីមែល',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Created At',
      dataIndex: 'CreateDate',
      key: 'CreateDate',
      render: (text, record) => (
        <span>{moment(record.CreateDate).format('DD-MM-YYYY')}</span>
      ),
    },
  ]

  return <Table columns={columns} dataSource={members.slice()} />
})
