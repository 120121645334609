import { applySnapshot, types } from 'mobx-state-tree'

import axios from 'axios'
import moment from 'moment'

const Token = types
  .model('Token', {
    id: '',
    ttl: types.maybe(types.number),
    created: '',
  })
  .views((self) => ({
    get isLoggedIn() {
      if (!self.ttl || self.created.length === 0) {
        return false
      }
      const { created, ttl } = self
      const expireDate = moment(created).add(ttl, 'seconds')
      const now = moment()
      const isExpired = now.isAfter(expireDate)
      return !isExpired
    },
  }))

const User = types
  .model('User', {
    id: '',
    name: '',
    username: '',
    createdAt: '',
    role: '',
    token: types.maybe(Token),
  })
  .actions((self) => ({
    setUser(user) {
      applySnapshot(self, user)
      setToken(user.token.id)
    },
    saveToStorage(user) {
      localStorage.setItem('user', JSON.stringify(user))
    },
    removeFromStorage() {
      localStorage.removeItem('user')
    },
    logout() {
      applySnapshot(self, { token: {} })
      localStorage.removeItem('user')
      setToken()
    },
  }))

let user = {
  token: {},
}
const storedUser = localStorage.getItem('user')
console.log('storedUser: ', storedUser)

if (storedUser) {
  user = JSON.parse(storedUser)
  setToken(user.token?.id)
}

function setToken(token) {
  console.log('setToken: ', token)
  axios.defaults.headers.common['Authorization'] = token
}

export const userStore = User.create(user)
