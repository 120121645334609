import * as Colors from 'Constants/Colors'

import { Button, Input, Spin, message } from 'antd'
import { IMember, memberStore } from 'Models/MemberModel'
import React, { useEffect, useState } from 'react'

import { MemberList } from './MemberList'
import { SearchOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

const Container = styled.section`
  height: 100%;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  background: ${Colors.SecondaryColor};
  padding-left: 20px;
  height: 53px;
`

const Spinner = styled(Spin)`
  margin: 30px;
`

interface Props {
  onAddButtonClick: () => void
  onMemberItemClick: (member: IMember) => void
}

export const MemberListContainer = observer((props: Props) => {
  const { onAddButtonClick } = props

  const { members, selectedMember } = memberStore
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    loadMembers('')
  }, [])

  async function loadMembers(nameFilter) {
    setLoading(true)

    const filter = {
      where: {
        name: {
          like: `${nameFilter}`,
          options: 'i',
        },
      },
    }

    try {
      await memberStore.loadMembers(filter)
    } catch (e) {
      message.error('Cannot load members ' + e.message)
      setLoading(false)
    }
    setLoading(false)
  }

  function onMemberItemClick(member: IMember) {
    memberStore.setSelectedMember(member.id)
    props.onMemberItemClick && props.onMemberItemClick(member)
  }

  async function onNameFilterChange(e) {
    const text = e.target.value
    loadMembers(text)
  }

  return (
    <Container>
      <Row>
        <h4>បញ្ជីសមាជិក</h4>
        <Button onClick={onAddButtonClick} type="primary">
          បន្ថែម
        </Button>
      </Row>

      <Input
        size="large"
        placeholder="Filter"
        prefix={<SearchOutlined />}
        onChange={onNameFilterChange}
      />

      {loading ? (
        <Spinner size="large"></Spinner>
      ) : (
        <MemberList
          selectedMemberId={selectedMember && selectedMember.id}
          onMemberItemClick={onMemberItemClick}
          members={members}
        ></MemberList>
      )}
    </Container>
  )
})
